import React from 'react';
import ConnectStripeButton from './ConnectStripeButton.js'

function StripeConnect({ user }) {
  return (
    <ConnectStripeButton
      userid={user.userid}
      email={user.email}
      username={user.username}
    />
  )
}
export default StripeConnect
