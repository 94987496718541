import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../lib/api';
import placeholder from '../../img/placeholder.png';

function Space ({ user, space, toggleUpdateModal, refreshSpacesOnUpdate }) {

  const [ activeLoading, toggleActiveLoading ] = useState(false)
  const mapLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(space.address)}`

  async function toggleActive () {
    try {
      toggleActiveLoading(true)
      const updatedSpace = await api('/spaces/toggleActive', { id: space._id, live: !space.live } )
      refreshSpacesOnUpdate(updatedSpace)
    } catch (error) {
      console.error(error)
    } finally {
      toggleActiveLoading(false)
    }
  }

  return (
    <div className='column col-4 col-sm-12 mt-2'>
      <div className='card'>

        <div className={`card-image`} style={{margin: 'auto'}}>
          <img style={{ maxWidth: '100%'}} className='img-responsive' src={space.image || placeholder} alt='space listing' />
        </div>

        <div className='card-header'>
          <div className='card-title'>
            <h4 className={`mb-1`}>{space.address.substring(0, space.address.indexOf(','))}</h4>
          </div>
          <div className='card-subtitle text-gray text-capitalize'>
            {space.type} {space.location && <a target='_blank' rel='noopener noreferrer' href={mapLink}>Open Maps <i className='icon icon-location'></i></a>}
          </div>
          {!space.live && <div className='card-subtitle mt-2 text-capitalize'>
            <span className='label label-rounded label-danger'>Deactivated</span>
          </div>}
        </div>

        <div className='card-body'>

          {space.about && <div>Access: {space.about}</div>}
          {
            space.bookings.length ?
              <Link to={{pathname: `/manage/${space._id}`, state: { space }}}>View Bookings ({space.bookings.length})</Link> :
              <h5 style={{marginTop: '0.5rem'}}>This space does not have any bookings yet.</h5>
          }
        </div>

        <div className='card-footer'>
          <div className='btn-group btn-group-block'>
            <button onClick={() => toggleUpdateModal(space)} className={`btn btn-primary`}>Edit</button>
             <button onClick={toggleActive} className={`${activeLoading ? 'loading btn' : 'btn'}`}>{space.live ? 'Deactivate' : 'Make Active'}</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Space;
