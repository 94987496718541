import React, { useState, useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom';
import api from '../../lib/api';
import auth from '../../lib/auth';
const queryString = require('query-string');

// http://localhost:3210/stripe/verify?code=ac_GIpmFEJwVpP2O5Uy9he4wjDGNzsBdiT6&state=HTFDR-Dni1MC

function StripeConnectVerify ({ location }) {
  const [loading, toggleLoading] = useState(true)
  const [error, toggleError] = useState(false)
  useEffect(() => {
    (async () => {
      console.dir(queryString.parse(location.search))
      let { code, state } = queryString.parse(location.search)
      if (code && state) {
        console.log(code, state)
        let { user } = await api('/auth/verifyStripeConnection', { code, userid: state })
        if (user.stripe_data) {
          auth.setUserInfo(user)
        } else {
          console.dir(user)
          toggleError('Failed to connect your Stripe account')
        }
        toggleLoading(false)
      }
    })()
  }, [location.search])
  return loading ?
      <p>Verifying your connection to Stripe, hold up...</p> :
      error ? <Redirect to={{pathname: '/settings#payments', state: { error: true, message: error }}} /> :
      <Redirect to={{pathname: '/settings#payments', state: {stripeConnected: true }}} />
}

export default withRouter(StripeConnectVerify)
