import React, { Component } from 'react';

class Activate extends Component {

  render() {
    return (
      <div>
        <h1>Account activated!</h1>
      </div>
    );
  }

}

export default Activate;
