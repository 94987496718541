import React from 'react';
import MarkerStyle from '../../styles/Marker.module.css';

export function SpaceMarker ({ val = 3, space, rateView = 'hourly', handleMarkerOpen, active }) {
  return (
    <div style={{paddingLeft: val > 10 || val.toString().includes('.') ? '1px' : '4px'}} className={MarkerStyle.mapMarker}>
      ${val}
    </div>
  )
}

export const MapMarker = ({ val = 3 }) => (
  <div style={{paddingLeft: val > 10 || val.toString().includes('.') ? '1px' : '4px'}} className={MarkerStyle.mapMarker}>
    ${val}
  </div>
)
