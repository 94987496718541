import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

export default class Autocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: props.address || ''
    }
  }
  handleChange = address => this.setState({ address })
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        console.dir(results)
        return getLatLng(results[0])
        .then(({ lat, lng }) => {
        this.setState({ address })
        this.props.locationSelect({lat, lng, address})
      })
    }).catch(error => console.error('Error', error));
  };
  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        shouldFetchSuggestions={this.state.address.length > 5}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='autocompleteParent' style={{width: '100%', position: 'relative'}}>
            <input
              {...getInputProps({
                placeholder: 'Where is your space?',
                className: 'location-search-input form-input'
              })}
            />
            <div className='autocomplete-dropdown-container' style={{position: 'absolute', top: '1.8rem', width: 'inherit', zIndex: 50}}>
              {suggestions.map((suggestion, i) => (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    className: suggestion.active ? 'suggestion-item--active text-ellipsis' : 'suggestion-item text-ellipsis',
                    style: {
                      backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                      cursor: 'pointer',
                      height: '2rem',
                      padding: '0.5rem',
                      border: '.05rem solid #bcc3ce',
                      borderRadius: '.1rem',
                      width: 'inherit'
                    }
                  })}
                >
                  <span style={{color:'black'}}>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
