import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';

const activeStyle = { boxShadow: 'rgb(0, 0, 0) 0px 5px 0px -2px' }

const BottomNav = (props) => (
  <footer className='navbar'>
    <section className='navbar-section'>
      <NavLink exact activeStyle={activeStyle} to='/map' className='bottomNavBtn'>
        <i className='icon icon-2x icon-location'></i>
      </NavLink>
      <NavLink exact activeStyle={activeStyle} to='/manage#spaces' className='bottomNavBtn'>
        <i className='icon icon-2x icon-menu'></i>
      </NavLink>
      {
        props.location.pathname !== '/map' ?
          <Link to='/map' onClick={() => props.toggleListModal(!props.showListModal)} className='btn btn-primary btn-action s-circle'>
            <i className='icon icon-plus'></i>
          </Link> :
          <button onClick={() => props.toggleListModal(!props.showListModal)} className='btn btn-primary btn-action s-circle'>
            <i className='icon icon-plus'></i>
          </button>
      }
      <NavLink exact activeStyle={activeStyle} to='/inbox' className='bottomNavBtn'>
        <i className='icon icon-2x icon-mail'></i>
      </NavLink>
      <NavLink exact activeStyle={activeStyle} to='/settings' className='bottomNavBtn'>
        <i className='icon icon-2x icon-people'></i>
      </NavLink>
    </section>
  </footer>
);

export default withRouter(BottomNav);
