import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import api from '../../lib/api';

const weekArray = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export default function ExtendBookingModal ({ booking, toggleExtendModal, refresh, S, stripe_cus_id}) {

  const [ newEndTime, setEndTime ] = useState(booking.end)
  const [ loading, toggleLoading ] = useState(false)
  const [ sameDayBookings, setSameDayBookings ] = useState([])
  const [ availabilityError, setAvailabilityError ] = useState('Please choose a new end time')
  const [ error, setError ] = useState(false)
  const [ success, toggleSuccess ] = useState(false)

  async function handleSubmit () {
    try {
      toggleLoading(true)
      let updatedBooking = await api('/bookings/extend', { booking, newEndTime, stripe_cus_id } )
      setError(false)
      refresh(updatedBooking)
      toggleSuccess(true)
      setTimeout(function () {
        toggleExtendModal(false)
      }, 2000)
    } catch (error) {
      console.error(error)
      setError(error.message)
    } finally {
      toggleLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      let getSameDayBookings = await api('/bookings/get', { query: { date: booking.date, _id: { $ne: booking._id }}})
      setSameDayBookings(getSameDayBookings)
    })()
  }, [booking.date, booking._id])

  useEffect(() => {
    console.log('check avail effect')
    let date = booking.date
    let space = booking.space
    let { availability } = space
    let d = dayjs(`${date}T12:00:00.00`)
    let s = dayjs(`${date}T${booking.start}:00.00`)
    let e = dayjs(`${date}T${newEndTime}:00.00`)
    let thisAvailability = availability[weekArray[d.get('d')]]
    let conflictingBooking = false
    if (thisAvailability.some(a => {
      // check if this proposed booking falls in the range of set availability
      let rStart = dayjs(`${date}T${a.start}:00.00`)
      let rEnd = dayjs(`${date}T${a.end}:00.00`)
      return s.isBetween(rStart, rEnd, 'minute', '[]') && e.isBetween(rStart, rEnd, 'minute', '[]')
    })) {
      if (sameDayBookings.some(b => {
        let bStart = dayjs(`${b.date}T${b.start}:00.00`)
        let bEnd = dayjs(`${b.date}T${b.end}:00.00`)
        // if an existing booking start or end is within the range of the proposed booking, error here
        if (s.isBetween(bStart, bEnd, 'minute', '[)') || e.isBetween(bStart, bEnd, 'minute', '(]')) {
          conflictingBooking = b
          return true
        } else return false
      })) {
        setAvailabilityError(`An existing booking from ${conflictingBooking.start} to ${conflictingBooking.end} conflicts with your selection.`)
      } else {
        setAvailabilityError(false)
      }
    } else {
      setAvailabilityError('Space unavailable at this time.')
    }
  }, [newEndTime, booking.space, booking.start, booking.date, sameDayBookings])

  return (
    <div className={`modal modal-sm active`}>
      <div className='modal-container'>
        <div className='modal-body'>
          <h5>Extend Booking</h5>
          <h6>{booking.space.address}</h6>
          <p className='card-subtitle text-gray'>{booking.start} - {booking.end}</p>
          <div className={`form-group ${S.extendForm}`}>
            <label htmlFor='newEndTime'>New End Time</label>
            <input id='newEndTime' type='time' min={booking.end} value={newEndTime} onChange={({ target }) => setEndTime(target.value)} />
          </div>
          {availabilityError && <p className='text-warning'>{availabilityError}</p>}
          {error && <p className='text-error'>{error}</p>}
        </div>
        <div className='modal-footer'>
          <button className={`btn float-left`} onClick={() => toggleExtendModal(false)}>Cancel</button>
          {success ? <p className='text-sucess'>Booking extended!</p> : <button className={`${availabilityError && 'disable'} btn btn-primary ${loading && 'loading'}`} onClick={handleSubmit}>Save</button>}
        </div>
      </div>
    </div>
  )
}
