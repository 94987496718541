import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import api from '../lib/api.js';
import { parseSpacesHash } from '../lib/parseHash';
import S from '../styles/Manage.module.css';
import Spaces from '../components/SpacesList';
import Bookings from '../components/BookingsList';

function Manage({ user, location }) {
  const [ spaces, setSpaces ] = useState([])
  const [ bookings, setBookings ] = useState([])
  const [ isLoadingSpaces, setIsLoadingSpaces ] = useState(true)
  const [ isLoadingBookings, setIsLoadingBookings ] = useState(true)
  const [ view, toggleView ] = useState(parseSpacesHash(location.hash))

  useEffect(() => {
    (async function loadData () {
      // TODO these API calls need optimized or batched
      try {
        let spaces = await api('/spaces/get', { query: { userid: user.userid } })
        let spaceBookings = await api('/bookings/get', { query: { status: { $ne: 'Cancelled' }, space: { $in: spaces.map(s => s._id) } } })
        setSpaces(spaces.map(s => Object.assign(s, { bookings: spaceBookings.filter(b => b.space === s._id) })))
        setIsLoadingSpaces(false)

        let bookings = await api('/bookings/get', { query: { userid: user.userid, status: { $ne: 'Cancelled' } } })
        let spaceIds = bookings.map(b => b.space)
        let bookedSpaces = await api('/spaces/get', { query: { _id: { $in: spaceIds } }})
        setIsLoadingBookings(false)
        setBookings(bookings.map(b => Object.assign(b, { space: bookedSpaces.find(s => s._id === b.space) })))
      } catch (error) {
        console.error(error)
      }
    })()
  }, [user.userid])

  useEffect(() => {
    toggleView(parseSpacesHash(location.hash))
  }, [location.hash])

  function refreshSpacesOnUpdate (space) {
    setSpaces(spaces.map(s => s._id === space._id ? Object.assign({}, s, space) : s))
  }

  function refreshBookingsOnUpdate (booking) {
    setBookings(bookings.map(b => b._id === booking._id ? Object.assign({}, b, booking, { space: b.space }) : b).filter(b => b.status !== 'Cancelled'))
  }

  return (
    <div className='viewParent'>
      <div className={S.spacesParent}>
        <ul className='tab tab-block'>
          <li className={`${view === 'spaces' ? 'active' : ''} tab-item`}>
            <a onClick={() => toggleView('spaces')} href='#spaces'>My Spaces</a>
          </li>
          <li className={`${view === 'bookings' ? 'active' : ''} tab-item`}>
            <a onClick={() => toggleView('bookings')} href='#bookings'>My Bookings</a>
          </li>
        </ul>

        <div className={`${S.spacesListParent} container`}>
          {
            view === 'spaces' ?
              <Spaces refreshSpacesOnUpdate={refreshSpacesOnUpdate} user={user} S={S} spaces={spaces} loading={isLoadingSpaces} /> :
              <Bookings
                refresh={refreshBookingsOnUpdate}
                user={user}
                S={S}
                upcoming={bookings.filter(b => b.endUnix > Date.now())}
                passed={bookings.filter(b => b.endUnix < Date.now())}
                loading={isLoadingBookings}
              />
          }
        </div>
      </div>
    </div>
  );
}

export default withRouter(Manage);
