import React, { Component } from 'react';
import api from '../lib/api';
import M from '../styles/List.module.css';
import { About, Photos, Price, Preview } from './partials/ListViews';
const steps = ['about', 'photos', 'price', 'preview'];

export default class UpdateModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      _id: '',
      warning: '',
      error: false,
      userid: props.user.userid,
      about: '',
      image: false,
      type: 'driveway',
      address: '',
      stripe_account: props.user.stripe_data,
      availability: false,
      view: 'about',
      locationSelected: true
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    try {
      this.setState({ loading: true })
      let space = await api('/spaces/update', this.state)
      console.log('got a space')
      console.dir(space)
      this.props.refreshSpacesOnUpdate(space)
      this.props.toggleUpdateModal(false)
    } catch (error) {
      console.log('caught error on post')
      console.error(error)
      this.setState({ error: true, warning: 'Failed to save this space', message: error.message || error.toString()})
     } finally {
       this.setState({ loading: false })
     }
  }
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })
  savePhotoToState = (url) => this.setState({ image: url })
  setTimesAndPrice = (hourly, daily, availability) => this.setState({ hourly, daily, availability })
  static getDerivedStateFromProps (props, prevState) {
    return prevState._id !== props.space._id ? { ...props.space } : null
  }
  render () {
    let { view, type, about, image, daily, hourly, availability } = this.state
    let width = window.innerWidth
    return (
      <div className={`${M.modalParent} modal modal-lg museo ${this.props.active ? 'active' : ''}`}>
        <div onClick={() => this.props.toggleUpdateModal(false)} className='modal-overlay' aria-label='Close'></div>
        <div className={`modal-container ${M.modalContainer} ${M.responsive}`}>

          <div className={`modal-header ${M.modalHeader} ${M.responsive}`}>
            <ul className='step'>
              {steps.map((viewOption, index) => (
                <li key={viewOption} className={`${M.stepItem} step-item ${viewOption === view ? 'active' : ''}`}>
                  {width <= 600 ? (
                    <a onClick={() => this.setState({ view: viewOption })} href={`#${viewOption}`} data-tooltip={viewOption} className={`tooltip tooltip-bottom ${M.stepLink}`}>{index}</a>
                  ) : (<a onClick={() => this.setState({ view: viewOption })} href={`#${viewOption}`} className={M.stepLink}>{viewOption}</a>)}
                </li>
              ))}
            </ul>
          </div>

          <div className={`${M.modalBody} modal-body`}>
            <div className='container'>
              <div className='content'>
                <div className={`form-group ${M.formParent}`}>
                  <About value={type} aboutText={about} onChange={this.handleChange} active={view === 'about'} />
                  <Photos M={M} savePhotoToState={this.savePhotoToState} active={view === 'photos'} image={image} />
                  {availability && <Price isUpdate={true} active={view === 'price'} d={daily} h={hourly} availability={availability} saveTimesAndPriceToState={this.setTimesAndPrice} />}
                  <Preview active={view === 'preview'} space={this.state} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${M.modalFooter} modal-footer`}>
            <div className='container'>
              {this.state.error && (
                <div className='columns'>
                  <div className='toast toast-error mt-2 mb-2' style={{textAlign: 'center', margin: 'auto', maxWidth: '600px'}}>
                    <button className='btn btn-clear float-right'></button>
                    {this.state.warning}: {this.state.message || 'An unknown error occurred'}
                  </div>
                </div>
              )}
              <div className={`hide-sm columns`}>
                <div className='column col-sm-6 col-1'>
                  <button onClick={() => this.props.toggleUpdateModal(false)} className={`btn ${M.controlButton}`}>Cancel</button>
                </div>
                <div className='column col-sm-6 col-2 col-ml-auto'>
                  {
                    (this.state.view === 'preview' || this.state.canSubmit) ?
                      <button disabled={!this.state.locationSelected} onClick={this.handleSubmit} className={`btn btn-primary ${M.controlButton} ${this.state.loading ? 'loading' : ''}`}>Save</button> :
                      <a onClick={() => this.setState({ view: steps[steps.indexOf(view) + 1] })} href={`#${steps[steps.indexOf(view) + 1]}`} className={`btn btn-primary ${M.controlButton}`}>Continue</a>
                  }
                </div>
              </div>
              <div className='btn-group btn-group-block show-sm'>
                <button onClick={() => this.props.toggleUpdateModal(false)} className={`btn ${M.mobileFooterBtn}`}>Cancel</button>
                {
                  (this.state.view === 'preview' || this.state.canSubmit) ?
                    <button disabled={!this.state.locationSelected} onClick={this.handleSubmit} className={`btn ${M.mobileFooterBtn} btn-primary ${this.state.loading ? 'loading' : ''}`}>Save</button> :
                    <a onClick={() => this.setState({ view: steps[steps.indexOf(view) + 1] })} href={`#${steps[steps.indexOf(view) + 1]}`} className={`btn btn-primary ${M.mobileFooterBtn}`}>Continue</a>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
