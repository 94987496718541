import React, { useState } from 'react';
import api from '../lib/api';

export default function MessageModal ({ user, re, toggleMessageModal, done = false }) {

  const [ body, editMessage ] = useState('')
  const [ loading, toggleLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ success, toggleSuccess ] = useState(false)

  async function handleSubmit () {
    try {
      toggleLoading(true)
      delete re._id
      await api('/inbox/create', Object.assign({}, re, {
        to: re.from,
        to_username: re.from_username,
        from: user.userid,
        from_avatar: user.avatar,
        from_username: user.username,
        space: re.space,
        booking: re.booking,
        body
      }))
      if (done) done()
      toggleSuccess(true)
      setTimeout(function () {
        toggleMessageModal(false)
      }, 2000)
    } catch (error) {
      console.error(error)
      setError(error.message)
    } finally {
      toggleLoading(false)
    }
  }
  return (
    <div className={`modal modal-sm active`}>
      <div className='modal-container'>
        <div className='modal-body'>
          <h5>Message {re ? re.from_username : re.booking.username}</h5>
          <h6>re: {re.space_address}</h6>
          <p className='card-subtitle text-gray'>Booked on {re.booking_date} at {re.booking_time}</p>
          <div className={`form-group`}>
            {/* <label htmlFor='message'>Message</label> */}
            <textarea className='form-input' rows={3} id='message' type='text' value={body} onChange={({ target }) => editMessage(target.value)}></textarea>
          </div>
          {error && <p className='text-error'>{error}</p>}

        </div>
        <div className='modal-footer'>
          <button className={`btn float-left`} onClick={() => toggleMessageModal(false)}>Cancel</button>}
          {success ? <p className='text-sucess'>Message sent!</p> : <button className={`btn btn-primary ${loading && 'loading'}`} onClick={handleSubmit}>Send</button>}
        </div>
      </div>
    </div>
  )
}
