import React from 'react';
import langara from '../img/schools/langara.jpg';
import capilano from '../img/schools/capilano.jpg';
import bcit from '../img/schools/bcit.png';
import fraser from '../img/schools/fraser.jpg';
import sfu from '../img/schools/sfu.png';
import twu from '../img/schools/twu.jpg';
import ubc from '../img/schools/ubc.png';
import pne from '../img/schools/pne.jpeg';
import Bulma from '../styles/Bulma.module.css';

function Schools ({ logo }) {
  return (
    <section className={`${Bulma.hero} ${Bulma['is-medium']} schoolsHero`} id='schools'>
      <div className={Bulma.container}>
        <p style={{paddingBottom: '2rem'}} className={`${Bulma.title} ${Bulma['is-size-2']} ${Bulma['has-text-centered']}`}>
          Use <img className='copyLogo logoInTitle' src={logo} alt='Trova' /> near these locations
        </p>
        <div className={`${Bulma.columns} ${Bulma.level}`}>
          <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={langara} alt='school logo' />
          </div>
          {/* <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={capilano} alt='school logo' />
          </div> */}
          <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={bcit} alt='school logo' />
          </div>
          {/* <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={fraser} alt='school logo' />
          </div> */}
          <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={sfu} alt='school logo' />
          </div>
          <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={pne} alt='Vancouver pacific national exhibit logo' />
          </div>
          {/* <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={twu} alt='school logo' />
            </div>
            <div className={`${Bulma['level-item']} ${Bulma.column} ${Bulma['has-text-centered']}`}>
            <img src={ubc} alt='school logo' />
          </div> */}
        </div>
        <div>
          <p className={`${Bulma['has-text-centered']}`}>
            Don't see your location? <br/> Let us know! <a href='mailto:contact@trovaapp.ca'>contact@trovaapp.ca</a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Schools

export const tileSchool = () => (
  <section className={`schoolsHero`} id='schools'>
    <div className={`${Bulma.tile} ${Bulma['is-ancestor']}`}>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={langara} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={capilano} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={bcit} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={fraser} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={sfu} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={twu} alt='school logo' />
      </div>
      <div className={`${Bulma['is-parent']} ${Bulma.tile}`}>
        <img className={`schoolImg ${Bulma['is-child']}`} src={ubc} alt='school logo' />
      </div>
    </div>
  </section>
)
