import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import auth from './lib/auth.js';
import App from './App';
import Landing from './views/Landing.js';
import Login from './views/Login.js';
import Register from './views/Register.js';
import Activate from './views/Activate.js';
import SocialLogin from './components/SocialLogin';
import StripeConnectVerify from './components/partials/StripeConnectVerify';
import * as serviceWorker from './serviceWorker';
import './styles/spectre.min.css';
import './styles/spectre-exp.min.css';
import './styles/spectre-icons.min.css';
import './App.css';
import './styles/Museo.css';

const queryString = require('query-string');

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      auth.getToken() !== null ? <Component user={auth.getUserInfo()} {...props} /> :
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )} />
  )
}

const HomeRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      auth.getToken() !== null ? <App {...props} user={auth.getUserInfo()} /> :
      <Redirect to={{
        pathname: '/welcome',
        state: { from: props.location }
      }} />
    )} />
  )
}

const OAuth = ({ ...rest }) => {
  let query = queryString.parse(rest.location.search)
  return (<SocialLogin {...rest} code={query.code} />)
}

const LogoutRoute = ({ component: Component, ...rest }) => {
  auth.clearAppStorage()
  return (<Redirect to={{pathname: '/login', state: {from: 'LogoutRoute'}}} />)
}

const PropsRoute = ({ component: Component, ...rest }) => <Component component={Component} {...rest} />

const renderRoutes = () => (
  <Router>
    <Switch>
      <HomeRoute exact path='/' />
      <Route exact path='/welcome' component={Landing} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/register' component={Register} />
      <LogoutRoute exact path='/logout' component={Login} />
      <Route exact path='/activate/:email' component={Activate} />
      <PropsRoute exact user={auth.getUserInfo()} path='/stripe/verify' component={StripeConnectVerify} />
      <OAuth exact path='/auth/facebook/callback' account='facebook' component={SocialLogin} />
      <OAuth exact path='/auth/google/callback' account='google' component={SocialLogin} />
      <PrivateRoute path='/' component={App} />
      <Redirect to='/' />
    </Switch>
  </Router>
)

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(renderRoutes(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
