import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import CreditCardInput from './CreditCardInput';

function AddPaymentMethod({ user, ...rest }) {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUB_KEY}>
      <Elements>
        <CreditCardInput
          email={user.email}
          username={user.username}
          {...rest}
          setError={(error) => {
            console.error(error)
            // TODO Call parent function and handle error in Settings view
          }}
        />
      </Elements>
    </StripeProvider>
  )
}
export default AddPaymentMethod
