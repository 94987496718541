import React from 'react';
import M from '../../styles/List.module.css';

export function Availability ({ availability }) {
  return (
    <div>
      {Object.keys(availability).map(day => {
        let a = availability[day]
        return a.length ? (
          <div className='chip' key={`previewChip_${day}`}>
            <div className='avatar' data-initial={day.substring(0, 1).toUpperCase() + day.substring(1, 2)}></div>
            {a.map((hrs, i) => {
              let hStart = hrs.start
              if (hStart.charAt(0) === '0') hStart = hrs.start.substring(1)
              let spanString = hStart === '0:00' && hrs.end === '23:59' ? 'All day' : `${hrs.start}-${hrs.end}`
              return (
                <span key={`hrsSpan${i}`} className={M.hrsSpan}>
                  {spanString}
                </span>
              )})}
          </div>
        ) : null
      })}
    </div>
  )
}
