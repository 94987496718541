import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/trovalogo.png';

function TopNav ({ Bulma }) {
  const [isActive, toggleActive] = useState(false)
  return (
    <nav className={`${Bulma.navbar} ${Bulma['is-transparent']} ${Bulma['is-fixed-top']}`}>
      <div className={`${Bulma['navbar-brand']}`}>
        <a href='/' className={Bulma['navbar-item']}>
          <img className={`${Bulma.navLogo} navLogo`} src={logo} alt='trova logo' />
        </a>
        <div
          className={`${Bulma['navbar-burger']} ${isActive ? Bulma['is-active'] : ''}`}
          onClick={() => toggleActive(!isActive)}
          style={{marginTop: '0.25rem'}}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`${Bulma['navbar-menu']} ${isActive ? Bulma['is-active'] : ''}`}>
        <div className={`${Bulma['navbar-start']}`}></div>
        <div className={`${Bulma['navbar-end']}`}>
          <a className={`${Bulma['navbar-item']} navLink`} href='#why'>Why</a>
          <a className={`${Bulma['navbar-item']} navLink`} href='#save'>Save</a>
          <a className={`${Bulma['navbar-item']} navLink`} href='#earn'>Earn</a>
          <div className={`${Bulma['navbar-item']}`}>
            <div className={Bulma.buttons}>
              <Link to='/login' className={`navLink ${Bulma['button']} ${Bulma['is-light']} ${Bulma['is-rounded']}`}>Login</Link>
              <Link to='/signup' className={`navLink ${Bulma.button} ${Bulma['is-rounded']} ${Bulma['is-primary']}`}>Sign Up</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopNav;
