import React from 'react';
import { Link } from 'react-router-dom';

export const Empty = ({ items = 'bookings', state = {}, actionText = 'get started', action = '/map', icon = 'icon-people' }) => (
  <div className='empty'>
    <div className='empty-icon'>
      <i className={`icon ${icon}`}></i>
    </div>
    <p className='empty-title h5'>You have no {items}</p>
    {actionText && <p className='empty-subtitle'>{actionText}.</p>}
    {action && <div className='empty-action'>
      <Link to={{ pathname: action, state }} className='btn btn-primary'>Start</Link>
    </div>}
  </div>
);
