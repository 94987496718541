import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import auth from './lib/auth.js';
import Map from './views/Map';
import Manage from './views/Manage';
import ManageSpace from './views/ManageSpace';
import Inbox from './views/Inbox';
import Settings from './views/Settings';
import Payments from './views/Payments';
import BottomNav from './components/BottomNav';

const App = (props) => {
  const [showListModal, toggleListModal] = useState(false)
  let user = auth.getUserInfo()
  return (
    <Router>
      <Switch>
        <Route exact render={() => <Map {...props} user={auth.getUserInfo()} toggleListModal={toggleListModal} showListModal={showListModal} />} path='/map' />
        <Route exact render={() => <Manage {...props} user={user} />} path='/manage' />
        <Route exact render={() => <ManageSpace {...props} user={user} />} path='/manage/:spaceid' />
        <Route exact render={() => <Inbox {...props} user={user} />} path='/inbox' />
        <Route exact render={() => <Payments {...props} user={user} />} path='/payments' />
        <Route exact render={() => <Settings {...props} user={user} />} path='/settings' />
        <Redirect to='/map' />
      </Switch>
      <BottomNav user={user} toggleListModal={toggleListModal} showListModal={showListModal} />
    </Router>
  )
}

export default App;
