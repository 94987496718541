import React, { useState, useEffect } from 'react';
import Autocomplete from '../Autocomplete';
import GMap from 'google-map-react';
import api from '../../lib/api';
import { MapMarker } from './MapMarker';
import placeholder from '../../img/placeholder.png';
import M from '../../styles/List.module.css';

export function About ({ value = 'sel', aboutText = '', onChange, active = false }) {
  if (!active) return null
  return (
    <div className='container'>
      <h4>Tell us about your space</h4>
      <select name='type' defaultValue={value} onChange={onChange} className={`form-select`}>
        <option value='sel' disabled>Select type of parking space</option>
        <option value='driveway'>Driveway</option>
        <option value='garage'>Garage / covered</option>
        <option value='street'>Street</option>
        <option value='gated'>Gated / condo</option>
        <option value='lot'>Outdoor lot</option>
      </select>
      <textarea value={aboutText} className={`form-input mt-2`} rows={4} onChange={onChange} name='about' placeholder='Quiet area, space for a sedan, park to the left of the garage'></textarea>
      <small>How to access, the size of your space, about the area and other good to knows</small>
    </div>
  )
}

export function Location ({ zoom = 9, locationSelect, gmapsLoaded, currentLocation, active = false, address, locationSelected }) {
  if (!active) return null
  if (!gmapsLoaded) return <span>Loading Google maps API</span>
  return (
    <div className='container'>

      <div className='column col-12' style={{marginBottom: '1rem'}}>
        <h4>Where is your space located?</h4>
      </div>

      <div className={`columns`} style={{height: `calc(100% - 2rem)`}}>

        <div className={`column col-6 col-sm-12 ${M.marginTop} ${M.autocomplete}`}>
          <Autocomplete locationSelect={locationSelect} address={address} locationSelected={locationSelected} />
        </div>
        <div className={`column col-6 col-sm-12 ${M.mapParent}`}>
          <GMap
            defaultCenter={{lat: 49.246292, lng: -123.116226}}
            center={currentLocation}
            zoom={zoom}
            bootstrapURLKeys={{key: process.env.REACT_APP_TROVA_MAPS_API_KEY + '&libraries=places'}}
            yesIWantToUseGoogleMapApiInternals={true}
          />
        </div>
      </div>
    </div>
  )
}

export function Price ({ active, d = 20, h = 3, availability, isUpdate = false, saveTimesAndPriceToState }) {

  const blankSlot = { start: '00:00', end: '23:59' }
  const week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const [hourly, setHourly] = useState(h)
  const [daily, setDaily] = useState(d)
  const [slotsByDay, updateSlots] = useState(availability || Object.fromEntries(week.map(d => [d, [blankSlot]])))
  useEffect(() => {
    if (typeof availability === 'object') updateSlots(availability)
  }, [availability])

  useEffect(() => {
    saveTimesAndPriceToState(hourly, daily, slotsByDay)
  }, [hourly, daily, slotsByDay, saveTimesAndPriceToState])

  return !active ? null : (
    <div className='container'>

      <div className='columns mb-2'>
        <div className='column col-12' style={{marginBottom: '1rem'}}>
          <h4>
            <span className='hide-sm'>Let's talk price and availability</span>
            <span className='show-sm'>Price and availability</span>
          </h4>
        </div>
        <div className='input-group column col-6 col-xs-12 mb-2'>
          <label style={{minWidth: '75px'}} className='form-label input-group-addon'>$ Hourly</label>
          <input className={`${M.priceInput} text-right form-input`} step='0.25' max='200' min='1' value={hourly.toString()} placeholder='3.00' type='number' name='hourly' onChange={(e) => setHourly(Number(e.target.value))} />
        </div>
        <div className='input-group column col-6 col-xs-12 mb-2'>
          <label style={{minWidth: '75px'}} className='form-label input-group-addon'>$ Daily</label>
          <input className={`${M.priceInput} text-right form-input`} step='1.00' max='500' min='1' value={daily.toString()} placeholder='20.00' type='number' name='daily' onChange={(e) => setDaily(Number(e.target.value))} />
        </div>
      </div>

      <div className={`columns mt-2 mb-2 ${M.daysParent}`}>
        {week.map((day, index) => {
          return (function TimeSlot () {
            const handleTimeChange = ({ target }) => {
              let list = slotsByDay[day]
              let updatedSlot = Object.assign({}, slotsByDay[day][target.dataset.index], {[target.name]: target.value})
              list.splice(target.dataset.index, 1, updatedSlot)
              updateSlots(Object.assign({}, slotsByDay, { [day]: list }))
            }
            function toggleDay (day, isDisabled) {
              updateSlots(Object.assign({}, slotsByDay, { [day]: isDisabled ? [blankSlot] : [] } ))
            }
            const toggleSlot = (action, thisSlotIndex) => {
              let updatedSlotList = action === 'add' ? slotsByDay[day].concat(blankSlot) : slotsByDay[day].filter((el, eli) => eli !== thisSlotIndex)
              updateSlots(Object.assign({}, slotsByDay, { [day]: updatedSlotList } ))
            }
            let disabled = slotsByDay[day].length === 0
            return (
              <div className='column col-6 col-xs-12' style={{marginBottom: '1rem'}} key={`${day}_${index}`}>

                <ul className={`menu ${M.menuParent}`}>
                  <li className='divider' data-content={day.toUpperCase()}></li>
                  <div className={`${M.dayToggle}`}>
                    <label className='form-switch'>
                      <input type='checkbox' name={day} checked={!disabled} onChange={() => toggleDay(day, disabled)} />
                      <i className='form-icon'></i>
                    </label>
                  </div>
                  {disabled ? (
                    <li key={`disabledslot_${day}`} className={`menu-item ${M.timeSlot}`}>
                      <div className={`columns`}>
                        <div className={`column col-1 ${M.addTimeButton}`}>
                          <button className='btn btn-action btn-primary btn-sm s-circle' disabled>
                            <i className={`icon icon-plus`}></i>
                          </button>
                        </div>
                        <div className='column col-4'>
                          <input className={`${M.dayTimeInput} input-sm form-input`} disabled={true} type='time' />
                        </div>
                        <div className={`divider vert`} data-content='to'></div>
                        <div className='column col-4'>
                          <input className={`${M.dayTimeInput} input-sm form-input`} disabled={true} type='time' />
                        </div>
                      </div>
                    </li>
                  ) : slotsByDay[day].map((slot, i) => (
                    <li key={`slot_${day}_${i}`} className={`menu-item ${M.timeSlot}`}>
                      <div className={`columns`}>
                        <div className={`column col-1 ${M.addTimeButton}`}>
                          <button className='btn btn-action btn-primary btn-sm s-circle' onClick={() => toggleSlot(i + 1 === slotsByDay[day].length && slotsByDay[day].length < 3 ? 'add' : 'remove', i)}>
                            <i className={`icon icon-${i + 1 === slotsByDay[day].length && slotsByDay[day].length < 3 ? 'plus' : 'minus'}`}></i>
                          </button>
                        </div>
                        <div className='column col-5'>
                          <input type='time' data-index={i} value={slot.start} step={60 * 15} name='start' className={`${M.dayTimeInput} input-sm form-input`} onChange={handleTimeChange} />
                        </div>
                        <div className='column col-1'>
                          <div className={`${M.to} divider vert`} data-content='to'></div>
                        </div>
                        <div className='column col-5'>
                          <input type='time' data-index={i} value={slot.end} step={60 * 15} name='end' className={`${M.dayTimeInput} input-sm form-input`} onChange={handleTimeChange} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })()
        })}
      </div>
    </div>
  )
}

export function Photos ({ M, savePhotoToState, active = false, image }) {
  const [loading, toggleLoading] = useState(false)
  const [error, toggleError] = useState(false)
  const [message, setMessage] = useState('')
  const [track, assignTrack] = useState({})
  const [cameraReady, toggleCameraReady] = useState(false)
  const [imageUrl, setImageUrl] = useState(image)

  useEffect(() => {
    setImageUrl(image)
  }, [image])

  const reset = () => {
    if ('stop' in track) track.stop()
    setImageUrl(false)
    toggleLoading(false)
    toggleCameraReady(false)
  }

  if (!active) {
    if ('stop' in track) track.stop()
    return null
  }

  const handleUpload = async (e, mode = 'files') => {
    try {
      toggleLoading(true)
      let formData = new FormData()
      formData.append('file', mode === 'files' ? e.target.files[0] : e)
      formData.append('fileName', `listingImage_${Math.floor(Math.random() * 100000)}`)
      formData.append('publicKey', process.env.REACT_APP_IMAGEKIT_PUB_KEY)

      let { token, signature, expire } = await api('/auth/imagekit', {}, 'GET')

      formData.append('signature', signature || '')
      formData.append('expire', expire || 0)
      formData.append('token', token)
      let uploadResponse = await fetch('https://api.imagekit.io/v1/files/upload', {
        'method': 'POST',
        'Content-Type' : 'multipart/form-data',
        'Accept': 'application/json',
        'body': formData
      })
      let uploadResult = await uploadResponse.json()
      savePhotoToState(`${uploadResult.url}?tr=w-300,h-300,pr-true`)
      setImageUrl(`${uploadResult.url}?tr=w-300,h-300,pr-true`)
      if ('stop' in track) track.stop()
    } catch (error) {
      console.error(error)
      toggleError(true)
      setMessage(error.message)
    } finally {
      toggleLoading(false)
      toggleCameraReady(false)
    }
  }
  const requestCamera = async () => {
    let constraints = { video: true, audio: false }
    try {
      navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        toggleCameraReady(true)
        assignTrack(stream.getVideoTracks()[0])
        let v = document.getElementById('video')
        if ('srcObject' in v) {
          v.srcObject = stream
        }
      })
      .catch(error => {
        console.error(error)
        toggleError(true)
        setMessage(error.message)
      })
    } catch (error) {
      console.error(error)
    }
  }
  const takePhoto = async () => {
    let imageCapture = new ImageCapture(track)
    let blob = await imageCapture.takePhoto()
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function() {
      handleUpload(reader.result, 'camera')
    }
  }
  return (
    <div className='container grid-sm'>
      <div className='columns'>
        <div className='column col-12'>
          <h4>Please upload a photo of your space</h4>
        </div>
        <div className={`${M.imageBody} column col-sm-12 col-6 col-mx-auto`}>
          <div className={`${M.uploadImageParent}`}>
            {cameraReady && track.readyState !== 'ended' ? (<video autoPlay playsInline width='300' height='300' id='video'></video>) : <img className='img-responsive' src={imageUrl || placeholder} alt='placeholder' />}
          </div>
          {!imageUrl ?
            <>
              <input className={`${M.rightAlignLoader} ${loading ? 'loading' : ''} mt-2 form-input`} onChange={handleUpload} name='photo' type='file' />
              {cameraReady && track.readyState !== 'ended' ?
                <button onClick={takePhoto} className={`${loading ? 'loading' : ''} width100 mt-2 btn btn-primary`}>Take Photo</button> :
                <button onClick={requestCamera} className='width100 mt-2 btn'>Use Camera
                  <i className='icon icon-photo ml-2'></i>
                </button>
              }
            </> : <button onClick={reset} className='width100 mt-2 btn btn-link'>Restart</button>}
          {error && <p>Hit an error: {message}</p>}
        </div>
      </div>
    </div>
  )
}

const p = {
  "loading": false,
  "canSubmit": false,
  "warning": "",
  "about": "drive up to it",
  "image": "https://ik.imagekit.io/trovapwa/listingImage_42921_zu8cKZ5k3",
  "type": "garage",
  "address": "1080 Lantzville School Road, Lantzville, BC, Canada",
  "locationSelected": true,
  "location": {
    "lat": 49.2508986,
    "lng": -124.07764789999999,
    "type": "Point"
  },
  "hourly": 4,
  "daily": 24,
  "availability": {
    "monday": [{start: "06:00", end: "21:00"}],
    "tuesday": [{start: "06:00", end: "21:00"}],
    "wednesday": [{start: "06:00", end: "21:00"}],
    "thursday": [{start: "06:00", end: "21:00"}],
    "friday": [{start: "06:00", end: "21:00"}],
    "saturday": [{start: "06:00", end: "14:00"}, {start: "16:00", end: "22:00"}],
    "sunday": []
  }
}

export function Preview ({ space = p, active = false }) {
  const [view, toggleView] = useState('map')
  const [rate, setRate] = useState('hourly')
  if (!active) return null

  return (
    <div className='container'>
      <div className='columns'>
        <div className='column col-6 col-mx-auto col-sm-12'>
          <div className='card'>
            <div className={`card-image ${view === 'map' && space.locationSelected ? M.mapPreviewParent : M.imgPreviewParent}`}>
              {view === 'map' && space.locationSelected ?
                <GMap
                  bootstrapURLKeys={{key: process.env.REACT_APP_TROVA_MAPS_API_KEY + '&libraries=places'}}
                  center={{lat: space.location.lat, lng: space.location.lng}}
                  defaultZoom={15}
                >
                  <MapMarker
                    lat={space.location.lat}
                    lng={space.location.lng}
                    val={space[rate]}
                  />
                </GMap>
              : <img style={{minHeight: window.innerWidth > 600 ? '300px' : '200px'}} className='img-responsive' src={space.image || placeholder} alt='your space listing' />}
            </div>

            <div className='card-header'>
              <div className='card-title'>
                <h4 className={`${M.previewHeader} mb-1`}>{space.address.substring(0, space.address.indexOf(','))}</h4>
              </div>
              <div className='card-subtitle text-gray mb-2 text-capitalize'>
                {space.type}
              </div>
              <div>
                <div className='btn-group'>
                  <button onClick={() => setRate('hourly')} className={`btn ${rate === 'hourly' ? 'btn-primary' : ''}`}>${space.hourly}/hour</button>
                  <button onClick={() => setRate('daily')} className={`btn ${rate === 'daily' ? 'btn-primary' : ''}`}>${space.daily}/day</button>
                </div>
                <button disabled={!space.locationSelected} className='btn float-right' onClick={() => toggleView(view === 'map' ? 'img' : 'map')}>
                  {view === 'map' ? <span>Photo <i className='icon icon-photo'></i></span> : <span>Map <i className='icon icon-flag'></i></span>}
                </button>
              </div>
            </div>

            <div className='card-body'>
              Access: {space.about}
            </div>

            <div className='card-footer'>
              {Object.keys(space.availability).map(day => {
                let a = space.availability[day]
                return a.length ? (
                  <div className='chip' key={`previewChip_${day}`}>
                    <div className='avatar' data-initial={day.substring(0, 1).toUpperCase() + day.substring(1, 2)}></div>
                    {a.map((hrs, i) => (
                      <span key={`hrsSpan${i}`} className={M.hrsSpan}>
                        {hrs.start.charAt(0) === '0' ? hrs.start.substring(1, hrs.start.length) : hrs.start}-{hrs.end}
                      </span>))}
                  </div>
                ) : null
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
