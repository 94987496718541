import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import api from '../../lib/api';
import auth from '../../lib/auth';

class CreditCardInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null,
      existingStripePMId: props.existingStripePM ? props.existingStripePM.id : false
    }
  }
  submit = async (ev) => {
    try {
      let { token } = await this.props.stripe.createToken();
      this.setState({ token: token.id })
      return token.id
    } catch (error) {
      this.props.setError({ error })
      return error
    }
  }
  createStripeCustomer = async () => {
    let { token } = this.state
    let { email, username } = this.props
    let user = await api('/auth/createstripecustomer', { token, email, username })
    console.dir(user)
    return user
  }
  createCustomer = async () => {
    this.setState({ loading: true })
    try {
      let createToken = await this.submit()
      console.log(`createToken: ${createToken}`)
      if (this.state.token) {
        let { user } = await this.createStripeCustomer()
        auth.setUserInfo(user)
        this.setState({ success: true, paymentMethod: user.paymentMethod})
        this.props.cardAdded(user.paymentMethod)
      } else {
        console.log(`no token in state...`)
        alert('Failed to create a token of your card. Please try again.')
      }
    } catch (error) {
      console.error(error)
      this.setState({ error })
    } finally {
      this.setState({ loading: false })
    }
  }
  render() {
    return (
      <div className='columns'>
        <div className='column col-10 pt-2'>
          <CardElement />
        </div>
        <div className='column col-auto col-ml-auto col-sm-12 saveUpdateBtnParent'>
          <button onClick={this.createCustomer} className={`${this.state.loading ? 'btn loading btn-block trovaBlue': 'btn btn-block trovaBlue'}`}>
            {this.props.role !== 'update' ? 'Save' : 'Update'}
          </button>
        </div>
      </div>
    )
  }
}

export default injectStripe(CreditCardInput)
