import React, { Component } from 'react';
import api from '../lib/api';
import M from '../styles/List.module.css';
import { About, Location, Photos, Price, Preview } from './partials/ListViews';
const steps = ['about', 'location', 'photos', 'price', 'preview'];

export default class ListModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      warning: '',
      error: false,
      userid: props.user.userid,
      username: props.user.username,
      about: '',
      image: false,
      type: 'driveway',
      address: '',
      stripe_account: props.user.stripe_data,
      locationSelected: false,
      location: {
        lat: 0,
        lng: 0
      },
      availability: {}
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    try {
      this.setState({ loading: true })
      let space = await api('/spaces/create', this.state)
      console.log('got a space')
      console.dir(space)
      this.props.history.replace('/map')
      this.setState({
        about: '',
        image: false,
        type: 'driveway',
        address: '',
        locationSelected: false,
        location: {
          lat: 0,
          lng: 0
        },
        availability: {}
      })
      this.props.addNewSpaceToMap(space)
    } catch (error) {
      console.log('caught error on post')
      console.error(error)
      this.setState({ error: true, warning: 'Failed to save this space', message: error.message || error.toString()})
     } finally {
       this.setState({ loading: false })
     }
  }
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })
  savePhotoToState = (url) => this.setState({ image: url })
  locationSelect = ({ lat, lng, address }) => this.setState({ address, location: { lat, lng, type: 'Point' }, locationSelected: true })
  setTimesAndPrice = (hourly, daily, availability) => this.setState({ hourly, daily, availability })
  render () {
    let { view } = this.props
    let width = window.innerWidth
    return (
      <div className={`${M.modalParent} modal modal-lg museo ${this.props.active ? 'active' : ''}`}>
        <div className='modal-overlay'></div>
        <div className={`modal-container ${M.modalContainer} ${M.responsive}`}>

          <div className={`modal-header ${M.modalHeader} ${M.responsive}`}>
            <ul className='step'>
              {steps.map((viewOption, index) => (
                <li key={viewOption} className={`${M.stepItem} step-item ${viewOption === view ? 'active' : ''}`}>
                  {width <= 600 ? (
                    <a href={`#${viewOption}`} data-tooltip={viewOption} className={`tooltip tooltip-bottom ${M.stepLink}`}>{index}</a>
                  ) : (<a href={`#${viewOption}`} className={M.stepLink}>{viewOption}</a>)}
                </li>
              ))}
            </ul>
          </div>

          <div className={`${M.modalBody} modal-body`}>
            <div className='container'>
              <div className='content'>
                <div className={`form-group ${M.formParent}`}>
                  <About value={this.state.type} aboutText={this.state.about} onChange={this.handleChange} active={view === 'about'} />
                  <Location
                    address={this.state.address}
                    M={M}
                    gmapsLoaded={this.props.gmapsLoaded}
                    currentLocation={this.state.locationSelected ? this.state.location : this.props.currentLocation}
                    locationSelect={this.locationSelect}
                    locationSelected={this.state.locationSelected}
                    zoom={this.state.locationSelected ? 13 : 7}
                    active={view === 'location'}
                  />
                  <Photos M={M} savePhotoToState={this.savePhotoToState} active={view === 'photos'} image={this.state.image} />
                  <Price active={view === 'price'} availability={false} saveTimesAndPriceToState={this.setTimesAndPrice} />
                  <Preview active={view === 'preview'} space={this.state} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${M.modalFooter} modal-footer`}>
            <div className='container'>
              {this.state.error && (
                <div className='columns'>
                  <div className='toast toast-error mt-2 mb-2' style={{textAlign: 'center', margin: 'auto', maxWidth: '600px'}}>
                    <button className='btn btn-clear float-right'></button>
                    {this.state.warning}: {this.state.message || 'An unknown error occurred'}
                  </div>
                </div>
              )}
              <div className={`hide-sm columns`}>
                <div className='column col-sm-6 col-1'>
                  <button aria-label='Close' onClick={this.props.toggle} className={`btn ${M.controlButton}`}>Cancel</button>
                </div>
                <div className='column col-sm-6 col-2 col-ml-auto'>
                  {
                    (this.props.view === 'preview' || this.state.canSubmit) ?
                      <button aria-label='Save' disabled={!this.state.locationSelected} onClick={this.handleSubmit} className={`btn btn-primary ${M.controlButton} ${this.state.loading ? 'loading' : ''}`}>Save</button> :
                      <a href={`#${steps[steps.indexOf(view) + 1]}`} className={`btn btn-primary ${M.controlButton}`}>Continue</a>
                  }
                </div>
              </div>
              <div className='btn-group btn-group-block show-sm'>
                <button aria-label='Close' onClick={this.props.toggle} className={`btn ${M.mobileFooterBtn}`}>Cancel</button>
                {
                  (this.props.view === 'preview' || this.state.canSubmit) ?
                    <button aria-label='Save' disabled={!this.state.locationSelected} onClick={this.handleSubmit} className={`btn ${M.mobileFooterBtn} btn-primary ${this.state.loading ? 'loading' : ''}`}>Save</button> :
                    <a href={`#${steps[steps.indexOf(view) + 1]}`} className={`btn btn-primary ${M.mobileFooterBtn}`}>Continue</a>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
