import React, { Component } from 'react';
import ButtonStyles from '../../styles/StripeButton.module.css'

class ConnectStripeButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      uri: ''
    }
  }
  static getDerivedStateFromProps (props, prevState) {
    return {
      uri: `https://connect.stripe.com/express/oauth/authorize?redirect_uri=\
${process.env.REACT_APP_STRIPE_REDIRECTURI}\
&scope=read_write\
&client_id=${process.env.REACT_APP_STRIPE_CLIENTID}\
&state=${props.userid}\
&stripe_user[email]=${props.email}\
&stripe_user[business_type]=individual\
&stripe_user[url]=https://trovaapp.ca\
`
    }
  }
  render () {
    return (
      <a href={this.state.uri} className={`${ButtonStyles.stripeConnect} ${ButtonStyles.dark}`}>
        <span>Connect with Stripe</span>
      </a>
    )
  }
}
export default ConnectStripeButton
