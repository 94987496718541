import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class SearchBox extends Component {
  componentDidMount() {
    let input = ReactDOM.findDOMNode(this.refs.searchInput);
    if (this.props.maps) {
      this.searchBox = new this.props.maps.places.SearchBox(input);
      this.searchBox.addListener('places_changed', this.onPlacesChanged);
    }
  }
  onPlacesChanged = () => {
    if (this.props.onPlacesChanged) {
      this.props.onPlacesChanged(this.searchBox.getPlaces());
    }
  }
  componentWillUnmount() {
    this.props.maps.event.clearInstanceListeners(this.searchBox);
  }
  render() {
    return (
      <input
        style={{
          position: 'fixed',
          top: '6px',
          padding: '5px'
        }}
        className='searchBoxInput'
        ref='searchInput'
        type='text'
        placeholder='Where to?'
      />
    )
  }
}
