import React, { useState, useEffect } from 'react';
import api from '../lib/api';
import { Empty } from '../components/partials/Empty';
import { Message } from '../components/partials/Message';
import MessageModal from '../components/MessageModal';

function Inbox ({ user }) {

  const [ inbox, setInbox ] = useState([])
  const [ loading, toggleLoading ] = useState(true)
  const [ showMessageModal, toggleMessageModal ] = useState(false)
  const [ re, setRe ] = useState(null)

  function startReply (message) {
    setRe(message)
    toggleMessageModal(true)
  }

  async function done () {
    const inbox = await api('/inbox/get', { query: { $or: [{ from: user.userid }, { to: user.userid }] }})
    setInbox(inbox)
  }

  useEffect(() => {
    (async () => {
      toggleLoading(true)
      const inbox = await api('/inbox/get', { query: { $or: [{ from: user.userid }, { to: user.userid }] }})
      toggleLoading(false)
      setInbox(inbox)
    })()
  }, [user.userid])

  return (
    <div className='viewParent'>
      <div className='container'>
        <div className='columns' style={{ padding: '2rem' }}>
          <div className='column col-8 col-mx-auto col-sm-12'>
            {loading ?
              <div className='loading mt-2'></div> :
              inbox.length ?
                inbox.map(m => <Message key={m._id} userid={user.userid} toggleMessageModal={toggleMessageModal} reply={startReply} message={m} />) :
                (
                  <Empty action={false} items='message' actionText='' icon='icon-mail' />
                )
            }
            {showMessageModal && <MessageModal done={done} user={user} re={re} toggleMessageModal={toggleMessageModal} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
