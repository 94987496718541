import React, { useState } from 'react';
import api from '../lib/api';

export default function StartMessageModal ({ user, booking, space, toggleMessageModal, done = false }) {

  const [ body, editMessage ] = useState('')
  const [ loading, toggleLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ success, toggleSuccess ] = useState(false)

  async function handleSubmit () {
    try {
      toggleLoading(true)
      await api('/inbox/create', {
        to: booking.userid,
        to_username: booking.username || space.username,
        from: user.userid,
        from_avatar: user.avatar,
        from_username: user.username,
        space: space._id,
        space_address: `${space.address.substring(0, space.address.indexOf(','))}`,
        booking: booking._id,
        booking_date: booking.date,
        booking_time: `${booking.start}-${booking.end}`,
        body
      })
      if (done) done()
      toggleSuccess(true)
      setTimeout(function () {
        toggleMessageModal(false)
      }, 2000)
    } catch (error) {
      console.error(error)
      setError(error.message)
    } finally {
      toggleLoading(false)
    }
  }
  return (
    <div className={`modal modal-sm active`}>
      <div className='modal-container'>
        <div className='modal-body'>
          <h5>Message {booking.username || booking.owner_username || 'owner'}</h5>
          <h6>re: {space.address.substring(0, space.address.indexOf(','))}</h6>
          <p className='card-subtitle text-gray'>{booking.start} - {booking.end} on {booking.date}</p>
          <div className={`form-group`}>
            <textarea className='form-input' rows={3} id='message' type='text' value={body} onChange={({ target }) => editMessage(target.value)}></textarea>
          </div>
          {error && <p className='text-error'>{error}</p>}

        </div>
        <div className='modal-footer'>
          <button className={`btn float-left`} onClick={() => toggleMessageModal(false)}>Cancel</button>
          {success ? <span className='text-sucess'>Message sent!</span> : <button className={`btn btn-primary ${loading && 'loading'}`} onClick={handleSubmit}>Send</button>}
        </div>
      </div>
    </div>
  )
}
