import React, { Component } from 'react';
import GMap from 'google-map-react';
import api from '../lib/api';
import { parseHash } from '../lib/parseHash';
import SearchBox from '../components/SearchBox';
import WelcomeModal from '../components/WelcomeModal';
import ListModal from '../components/ListModal';
import { ConnectStripePrompt } from '../components/partials/ConnectStripePrompt';
import { SpaceModal } from '../components/SpaceModal';
import { SpaceMarker } from '../components/partials/MapMarker';
import Tawk from '../components/partials/Tawk';

class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadingSpaces: true,
      error: false,
      createSpaceModal: false,
      spaces: [],
      zoom: 12,
      lat: 49.246292,
      lng: -123.116226,
      showWelcomeModal: props.location.state ? props.location.state.from === 'register' : false,
      includeWelcomeModal: props.location.state ? props.location.state.from === 'register' : false,
      showListModal: false,
      gmapsLoaded: false,
      maps: {}
    }
  }
  static getDerivedStateFromProps (props, prevState) {
    return props.showListModal !== prevState.showListModal ? { showListModal: true || props.showListModal } : null
  }
  async componentDidMount () {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt()
    }
    try {
      let spaces = await api('/spaces/get', { query: { live: true } })
      this.setState({ spaces, loadingSpaces: false })
    } catch (error) {
      console.error(error)
      this.setState({ error, loadingSpaces: false })
    }
  }
  gmapsLoaded = (maps) => this.setState({ gmapsLoaded: true, maps })
  toggleWelcomeModal = (e) => this.setState({ showWelcomeModal: !this.state.showWelcomeModal })
  showListModal = (e) => {
    this.props.toggleListModal(!this.props.showListModal)
    this.toggleWelcomeModal()
  }
  handleMapMove = ([place]) => {
    this.setState({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      zoom: 14,
      placeSelected: true
    })
  }
  handleMarkerOpen = (id) => {
    let selectedSpace = this.state.spaces.find(s => s._id === id)
    this.setState({ selectedSpace, activeMarker: id, lat: selectedSpace.location.lat, lng: selectedSpace.location.lng, active: 'active' })
  }
  handleMarkerClose = () => this.setState({ active: '', activeMarker: null })
  addNewSpaceToMap = (space) => this.setState({
    spaces: this.state.spaces.concat(space),
    lat: space.location.lat,
    lng: space.location.lng,
    zoom: 14,
    connectStripe: Boolean(!space.stripe_account)
  }, () => this.props.toggleListModal(false))

  render() {
    let { lat, lng } = this.state
    return (
      <div className={`mapParent viewParent ${this.state.loadingSpaces ? 'loading' : 'ready'}`}>
        <GMap
          defaultCenter={{ lat: 49.246292, lng: -123.116226 }}
          center={{ lat, lng }}
          defaultZoom={12}
          zoom={this.state.zoom}
          options={{
            fullscreenControl: false,
            panControl: false,
            zoomControl: false
          }}
          bootstrapURLKeys={{key: process.env.REACT_APP_TROVA_MAPS_API_KEY + '&libraries=places'}}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.gmapsLoaded(maps)}
          onChildClick={(id) => this.handleMarkerOpen(id)}
          onChildMouseEnter={(id) => this.setState({ activeMarker: id })}
        >
          {this.state.spaces.map(s => {
            return (
              <SpaceMarker
                id={s._id}
                lat={s.location.lat}
                lng={s.location.lng}
                key={s._id}
                val={s.hourly}
                handleMarkerOpen={this.handleMarkerOpen}
                space={s}
                active={this.state.activeMarker === s._id}
              />
            )
          })}
        </GMap>
        {this.state.gmapsLoaded && <SearchBox onPlacesChanged={this.handleMapMove} maps={this.state.maps} />}
        {/* {this.state.gmapsLoaded && this.state.placeSelected && <DateTimeBox />} */}
        {this.state.includeWelcomeModal && <WelcomeModal active={this.state.showWelcomeModal} showListModal={this.showListModal} toggleModal={this.toggleWelcomeModal} />}

        <ListModal
          gmapsLoaded={this.state.gmapsLoaded}
          active={this.props.showListModal}
          toggle={() => this.props.toggleListModal(!this.props.showListModal)}
          view={parseHash(this.props.location.hash)}
          currentLocation={{ lat, lng }}
          user={this.props.user}
          addNewSpaceToMap={this.addNewSpaceToMap}
          history={this.props.history}
        />
        {this.state.active &&
          <SpaceModal
            closeSpaceModal={this.handleMarkerClose}
            active={this.state.active}
            space={this.state.selectedSpace}
            userid={this.props.user.userid}
            username={this.props.user.username}
            avatar={this.props.user.avatar}
            stripe_cus_id={this.props.user.stripe_cus_id}
            pm={this.props.user.paymentMethod}
          />}
        {this.state.connectStripe && (
          <ConnectStripePrompt toggle={() => this.setState({ connectStripe: false })} />
        )}
        <Tawk page='map' />
      </div>
    );
  }

}

export default Map;
