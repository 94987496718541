import React from 'react';
import Booking from './partials/Booking';
import { Empty } from './partials/Empty';

function Bookings({ loading, user, upcoming, passed, S, refresh }) {
  return loading ? (
    <div className='loading loading-lg'></div>
  ) : (upcoming.length || passed.length) ? (
    <div className='container'>
      <div className='columns'>
        {upcoming.map(booking => (
          <Booking
            refresh={refresh}
            user={user}
            booking={booking}
            key={booking._id}
            S={S}
          />)
        )}
        {passed.map(booking => (
          <Booking
            refresh={refresh}
            user={user}
            booking={booking}
            key={booking._id}
            S={S}
          />)
        )}
      </div>
    </div>
  ) : (
    <Empty action='/map' items='booking' actionText='Use the map to book a space' icon='icon-location' />
  )
}

export default Bookings;
