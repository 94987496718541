import React, { useState } from 'react';
import api from '../../lib/api';
import ExtendBookingModal from './ExtendBookingModal';
import StartMessageModal from '../StartMessageModal';
import placeholder from '../../img/placeholder.png';
import dayjs from 'dayjs';

function Booking ({ user, booking, refresh, S }) {
  const [ error, setError ] = useState('')
  const [ loadingCancel, toggleLoadingCancel ] = useState(false)
  const [ showExtendModal, toggleExtendModal ] = useState(false)
  const [ showMessageModal, toggleMessageModal ] = useState(false)
  let { space, rate } = booking
  let now = dayjs()
  // let start = dayjs.unix(booking.startUnix / 1000)
  let end = dayjs.unix(booking.endUnix / 1000)
  let timeString = booking.rate === 'daily' ? 'All day' : `${booking.start.charAt(0) === '0' ? booking.start.substring(1, booking.start.length) : booking.start}-${booking.end}`
  // let end = dayjs.unix(booking.endUnix / 1000)
  const mapLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(space.address)}`
  const cancel = async () => {
    if (window.confirm('Cancel this booking? Your card will be refunded and the transfer to the owner reversed.')) {
      try {
        toggleLoadingCancel(true)
        let updatedBooking = await api('/bookings/cancel', { booking } )
        refresh(Object.assign({}, booking, updatedBooking))
        toggleLoadingCancel(false)
      } catch (error) {
        console.error(error)
        setError(error.message)
        toggleLoadingCancel(false)
      }
    }
  }
  return (
    <div className={`column col-mx-auto col-6 col-md-12 ${S.booking}`}>
      <div className={`tile ${S.tile}`}>
        <div className={`tile-icon ${S.imageParent}`}>
          <img className='img-responsive bookingImage' src={space.image || placeholder} alt='space listing' />
        </div>
        <div className={`tile-content ${S.tileContent}`}>
          <h5 className='tile-title mb-1'>
            {space.address.substring(0, space.address.indexOf(','))} <a target='_blank' rel='noopener noreferrer' href={mapLink}><small>Open Maps <i className='icon icon-location'></i></small></a>
          </h5>
          <p className='tile-subtitle mb-2'>
            <small className={`label label-rounded label-primary`}>${space[booking.rate]}{booking.rate === 'hourly' ? '/hour' : '/day'}</small> <span className='card-subtitle text-gray'>{space.type}</span>
          </p>
          <p className='mb-2'>
            {timeString} on {booking.date}
            <br />
            <span>Access: {space.about}</span>
          </p>
          {/* TODO Extend time */}
          {end.isAfter(now) &&
            <div>
              {rate !== 'daily' && <button onClick={() => toggleExtendModal(true)} className={`btn btn-sm btn-primary mr-2`}>Extend</button>}
              <button onClick={cancel} className={`${loadingCancel && 'loading'} btn btn-sm mr-2`}>Cancel</button>
              {end.isAfter(now) &&
                <button onClick={() => toggleMessageModal(true)} className={`btn btn-sm`}><i className='icon icon-mail centered'></i> Contact</button>
              }
            </div>
          }
          {error && <p className='mt-2 mb-1'>Update failed: {error}</p>}
        </div>
      </div>
      {showExtendModal &&
        <ExtendBookingModal
          booking={booking}
          refresh={refresh}
          stripe_cus_id={user.stripe_cus_id}
          toggleExtendModal={toggleExtendModal}
          S={S}
        />}
      {showMessageModal &&
        <StartMessageModal
          user={user}
          booking={booking}
          space={space}
          toggleMessageModal={toggleMessageModal}
        />}
    </div>
  )
}

export default Booking;
