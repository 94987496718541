import auth from './auth.js'

const api = (path, body, method = 'post') => {
  // console.log(`[API] ${path} ${JSON.stringify(body)} ${method}`)
  try {
    const headers = { 'Authorization': method === 'post' ? auth.getToken() : null, 'Accept': 'application/json', 'Content-Type': 'application/json'}
    return fetch(`/api${path}`, {
      method,
      headers,
      credentials: 'include',
      body: method === 'GET' ? null : method === 'PUT' ? body : JSON.stringify(body)
    })
    .then(response => {
      if (response.type === 'cors' || response.status === 204) return response
      if (['400', '401', '402', '403', '404', '500', '501', '502', '503'].includes(response.status.toString()) || !response.ok) {
        if (response.text) {
          return response.text().then(text => { throw new Error(text) })
        } else {
          throw new Error(`Request failed: unknown server error.`)
        }
      }
      return (typeof response.json !== 'undefined' || response.body) ? response.json() : response
    }).then(data => data)
  } catch (error) {
    console.log(`catching at api.js[18]: ${error}`)
    return error
  }
}
export default api
