export const parseHash = hash => hash.includes('about') ? 'about' :
  hash.includes('location') ? 'location' :
  hash.includes('description') ? 'description' :
  hash.includes('photos') ? 'photos' :
  hash.includes('price') ? 'price' :
  hash.includes('preview') ? 'preview' : 'about'

export const parseSettingsHash = hash => hash.includes('profile') ? 'profile' : hash.includes('payments') ? 'payments' : hash.includes('account') ? 'account' : 'payments'

export const parseSpacesHash = hash => hash.includes('bookings') ? 'bookings' : 'spaces'
