import React, { useState } from 'react';
import Space from './partials/Space';
import UpdateModal from './UpdateModal';
import { Empty } from './partials/Empty';

function Spaces({ loading, user, spaces, S, refreshSpacesOnUpdate }) {
  const [ showUpdateModal, toggleUpdateModal ] = useState(false)
  const [ spaceToUpdate, setSpaceToUpdate ] = useState({})

  const openUpdateModal = function (space) {
    setSpaceToUpdate(space)
    toggleUpdateModal(true)
  }

  return loading ? (
    <div className='loading loading-lg'></div>
  ) : spaces.length ? (
    <div className='container'>
      <div className='columns'>
        {spaces.map(space => <Space user={user} toggleUpdateModal={openUpdateModal} refreshSpacesOnUpdate={refreshSpacesOnUpdate} space={space} key={space._id} />)}
      </div>
      <UpdateModal
        active={showUpdateModal}
        toggleUpdateModal={toggleUpdateModal}
        user={user}
        space={spaceToUpdate}
        refreshSpacesOnUpdate={refreshSpacesOnUpdate}
      />
    </div>
  ) : (
    <Empty action={false} items='spaces listed' actionText='Tap the plus icon bellow to list a space' icon='icon-minus' />
  )
}

export default Spaces;
