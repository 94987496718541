import React from 'react';
import { Link } from 'react-router-dom';

export function ConnectStripePrompt ({ toggle }) {
  const style = {
    position: 'fixed',
    top: '80px',
    right: '0',
    padding: '10px',
    margin: 'auto',
    width: '90vw',
    maxWidth: '600px',
  }
  return (
    <div className='toast toast-success' style={style}>
      <span>Space listed!</span>
      <i onClick={toggle} className='icon icon-clear btn-clear float-right'></i>
      <br />
      <span>You've listed a space, now it's time to connect your bank account to get paid!</span>
      <br />
      <Link to='/settings#payments'>Connect now</Link>
    </div>
  )
}
