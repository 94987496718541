import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import { Availability } from './partials/Availability';
import { BookingComplete } from './partials/BookingComplete';
import api from '../lib/api';
import placeholder from '../img/placeholder.png';
import lightning from '../img/lightning.png';

dayjs.extend(isBetween)

const weekArray = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const SpaceModal = ({ space, active, closeSpaceModal, userid, username, pm, stripe_cus_id }) => {
  const today = dayjs()
  const minDate = today.format('YYYY-MM-DD')
  const [ rate, setRate ] = useState('hourly')
  const [ loading, toggleLoading ] = useState(false)
  const [ booking, toggleBooking ] = useState(false)
  const [ success, toggleSuccess ] = useState(false)
  const [ spaceBookings, setSpaceBookings] = useState([])
  const [ newBooking, setBooking ] = useState(null)
  const [ start, setTimeStart ] = useState('08:00')
  const [ end, setTimeEnd ] = useState('18:00')
  const [ startUnix, setStartUnix ] = useState(today.set('hour', 8).set('minute', 0).valueOf())
  const [ endUnix, setEndUnix ] = useState(today.set('hour', 18).set('minute', 0).valueOf())
  const [ date, setDate ] = useState(today.add(1, 'day').format('YYYY-MM-DD'))
  const [ price, setPrice ] = useState(Math.min(space.daily, 10 * space.hourly))
  const [ error, setError ] = useState(false)
  const [ availabilityError, setAvailabilityError ] = useState(false)

  const book = async (e) => {
    e.preventDefault()
    try {
      toggleLoading(true)
      let newBooking = await api('/bookings/create', { space, userid, username, start, startUnix, end, endUnix, date, rate, price, stripe_cus_id })
      console.dir(newBooking)
      toggleLoading(false)
      toggleBooking(false)
      setBooking(newBooking)
      setSpaceBookings(spaceBookings.concat(newBooking))
      toggleSuccess(true)
    } catch (error) {
      console.log('caught error in space modal')
      console.error(error)
      setError(error.message)
      toggleLoading(false)
    }
  }

  const handleTimeChange = ({ target }) => {
    if (target.name === 'start') setTimeStart(target.value)
    if (target.name === 'end') setTimeEnd(target.value)
    let startDay = dayjs(`${date}T${target.name === 'start' ? target.value : start}:00.00`)
    let endDay = dayjs(`${date}T${target.name === 'end' ? target.value : end}:00.00`)
    setStartUnix(startDay.valueOf())
    setEndUnix(endDay.valueOf())
    if (startDay.isAfter(endDay, 'minute') || startDay.isSame(endDay, 'minute') || startDay.isBefore(dayjs())) {
      setError('Invalid times selected, please adjust your request.')
    } else {
      setPrice(Math.min(space.daily, endDay.diff(startDay, 'hours', true) * space.hourly))
      setError(false)
    }
  }

  const useDailyRate = ({ target }) => {
    setRate('daily')
    setTimeStart('00:00')
    setTimeEnd('23:59')
    setStartUnix(dayjs(`${date}T00:00:00.00`).valueOf())
    setEndUnix(dayjs(`${date}T23:59:00.00`).valueOf())
    setPrice(space.daily)
  }

  const useHourlyRate = ({ target }) => {
    setRate('hourly')
    setTimeStart('08:00')
    setTimeEnd('18:00')
    setStartUnix(dayjs(`${date}T08:00:00.00`).valueOf())
    setEndUnix(dayjs(`${date}T18:00:00.00`).valueOf())
    setPrice(Math.min(space.daily, 10 * space.hourly))
  }

  useEffect(() => {
    let { availability } = space
    let d = dayjs(`${date}T12:00:00.00`)
    let s = dayjs(`${date}T${start}:00.00`)
    let e = dayjs(`${date}T${end}:00.00`)
    let thisAvailability = availability[weekArray[d.get('d')]]
    let conflictingBooking = false
    if (thisAvailability.some(a => {
      // check if this proposed booking falls in the range of set availability
      let rStart = dayjs(`${date}T${a.start}:00.00`)
      let rEnd = dayjs(`${date}T${a.end}:00.00`)
      return s.isBetween(rStart, rEnd, 'minute', '[]') && e.isBetween(rStart, rEnd, 'minute', '[]')
    })) {
      if (spaceBookings.some(b => {
        let bStart = dayjs(`${b.date}T${b.start}:00.00`)
        let bEnd = dayjs(`${b.date}T${b.end}:00.00`)
        // if an existing booking start or end is within the range of the proposed booking, error here
        if (s.isBetween(bStart, bEnd, 'minute', '[)') || e.isBetween(bStart, bEnd, 'minute', '(]')) {
          conflictingBooking = b
          return true
        } else return false
      })) {
        setAvailabilityError(`An existing booking from ${conflictingBooking.start} to ${conflictingBooking.end} conflicts with your selection.`)
      } else {
        setAvailabilityError(false)
      }
    } else {
      setAvailabilityError('Space unavailable at this time.')
    }
  }, [start, end, date, space, spaceBookings])

  useEffect(() => {
    try {
      (async () => {
        let spaceBookings = await api('/bookings/get', { query: { space: space._id, endUnix: { $gt: dayjs().valueOf() }, status: { $ne: 'Cancelled' } } })
        setSpaceBookings(spaceBookings)
      })()
    } catch (error) {
      console.error(error)
    }
  }, [space._id])

  return (
    <div className={`modal ${active} modal-sm`} id={space._id}>

      <div onClick={closeSpaceModal} className='modal-overlay' aria-label='Close'></div>

      <div className='modal-container' style={{padding: 0, position: 'relative'}}>

        <button onClick={closeSpaceModal} className='btn btn-clear' style={{position: 'absolute', top: '8px', right: '8px', zIndex: 5}} aria-label='Close'></button>

        <div className='modal-body' style={{padding: 0}}>

          <div className='card'>
            {!booking && <div className={`card-image`} style={{margin: 'auto'}}>
              <img style={{ maxWidth: '100%'}} className='img-responsive' src={space.image || placeholder} alt='space listing' />
            </div>}

            <div className='card-header'>
              <div className='card-title'>
                <h4 className={`mb-1`}>{space.address.substring(0, space.address.indexOf(','))}</h4>
              </div>
              <div className='card-subtitle text-gray mb-2 text-capitalize'>
                {space.type}
              </div>
              <div>
                <div className='btn-group'>
                  <button onClick={useHourlyRate} className={`btn ${rate === 'hourly' ? 'btn-primary' : ''}`}>${space.hourly}/hour</button>
                  <button onClick={useDailyRate} className={`btn ${rate === 'daily' ? 'btn-primary' : ''}`}>${space.daily}/day</button>
                </div>
              </div>
            </div>

            {space.about && <div className='card-body'>
              Access: {space.about}
            </div>}

            {success ? (<BookingComplete booking={newBooking} space={space} />) :
            <div className='card-footer'>
              <Availability availability={space.availability} />

              {booking ? (
                <div className='bookingParent' style={{marginTop: '1rem'}}>
                  <form className='form-horizontal' onSubmit={book}>
                    <div className='form-group mb-2'>
                      <div className='column col-2'>
                        <label className='form-label' htmlFor='bookingStart'>
                          Start
                        </label>
                      </div>
                      <div className='column col-4'>
                        <input required pattern="[0-9]{2}:[0-9]{2}" step={900} disabled={rate === 'daily'} value={start} onChange={handleTimeChange} id='bookingStart' className='form-input' type='time' name='start' />
                      </div>
                      <div className='column col-2'>
                        <label className='form-label' htmlFor='bookingEnd'>
                          End
                        </label>
                      </div>
                      <div className='column col-4'>
                        <input required pattern="[0-9]{2}:[0-9]{2}" step={900} disabled={rate === 'daily'} value={end} onChange={handleTimeChange} id='bookingEnd' className='form-input' type='time' name='end' />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='column col-2'>
                        <label className='form-label' htmlFor='bookingDate'>
                          Date
                        </label>
                      </div>
                      <div className='column col-10'>
                        <input min={minDate} onChange={({ target }) => setDate(target.value)} value={date} id='bookingDate' className={`${error ? 'is-error' : ''} form-input`} type='date' name='date' />
                        {error ? <div className='form-input-hint'>{error}</div> : availabilityError ? <div className='form-input-hint'>{availabilityError}</div> : null}
                      </div>
                    </div>
                    <div className='form-group' style={{marginTop: '0.75rem'}}>
                      <div className='column col-4'>
                        <b style={{fontSize: '2rem'}}>${rate === 'hourly' ? price : space.daily}</b>
                      </div>
                      <div className='column col-8 pt-1 text-right'>
                        <button disabled={!pm || error || availabilityError} className={`btn btn-primary mt-2 ${loading ? 'loading' : ''}`}>Book Space</button>
                      </div>
                      <div className='form-input-hint mt-2'>
                        {
                          pm ?
                          `Your ${pm} will be charged the amount shown above` :
                          <span className='toast toast-error'>You must <Link className='link' to='/settings#payments'>add a payment method</Link> to your account to book a space.</span>
                        }
                      </div>
                    </div>
                  </form>
                </div>) : space.userid !== userid ? (
                  <div style={{marginTop: '0.75rem'}}>
                    <button style={{width: '100%'}} onClick={() => toggleBooking(true)} className={`btn btn-block btn-primary`}>
                      Book Now <img src={lightning} alt='lightning' className='lightningIcon' />
                    </button>
                  </div>
                ) : (
                  <div style={{marginTop: '0.75rem'}}>
                    <h6>This is your space. <a href={`/manage/${space._id}`}>Manage</a></h6>
                  </div>
                )}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
