import React, { Component } from 'react';

class Payments extends Component {

  render() {
    return (
      <h1>Payments</h1>
    );
  }

}

export default Payments;
