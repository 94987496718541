import React from 'react';
import placeholder from '../../img/car-sq.png';

export function Message ({ userid, message, reply, toggleMessageModal }) {

  return (
    <div key={message._id} className={`column col-mx-auto col-10 col-md-12 mb-2`}>
      <div className={`tile`} style={message.from === userid ? { flexDirection: 'row-reverse' } : {}}>
        <div className={`tile-icon`}>
          <img className='avatar avatar-xl img-responsive' src={message.from_avatar || placeholder} alt='space listing' />
        </div>
        <div className={`tile-content`}>
          <h5 className='tile-title mb-1'>
            {message.from === userid ? `You wrote:` : message.from_username}
          </h5>
          <p className='mb-2'>
            {message.body}
          </p>
          <small>Re: {message.space_address} ({message.booking_time} on {message.booking_date})</small>
          <p className='tile-subtitle text-gray'>
            {
              message.from === userid ?
                `Sent to ${message.to_username} on ${message.sent.substring(0, message.sent.indexOf('+'))}` :
                `Sent on ${message.sent.substring(0, message.sent.indexOf('+'))}`
            }
          </p>
        </div>
        {message.from !== userid && <div className={`tile-action`}>
          <span onClick={() => reply(message)} className={`label label-rounded label-primary px-2 pointer`}>
            <i className='icon icon-mail centered'></i> Reply
          </span>
        </div>}
      </div>
    </div>
  )
}
